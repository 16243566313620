<template>
    <div v-bind:data-BoardId="item._id" v-bind:class="'statusTaskItemCls-' + item._id" class="list-group-item tsk_item_card_cls boardClsId" v-for="element in item.data" :key="element.title">
        <div class="itemMainCls taskTitleCls" v-bind:dataId="element.task_id">
            <input type="hidden" name="taskId" class="taskId" v-bind:class="'taskId-' + element.task_id" v-bind:value="element.task_id"/>
            <div class="kanTImgsCls" v-if="isImage(element.attachmentsImage)">
                <img v-bind:src="img_url+''+element.attachmentsImage" class="img-thumbnail" style="width:100%"  @error="imageLoadError(element.task_id)" >   
            </div>
            <div class="kanTitleCls">
                <!-- <div v-html="readMore(element.title, element.task_id)" />      -->
                <span v-bind:class="'kanTitCls-'+element.task_id">{{ element.title }}</span>
                <b>#{{ element.task_no }}</b>
            </div>
            <div class="kanPriNameMainCls" v-bind:class="'kanPriCls-'+element.task_id">
                <div class="kanPriNameCls" v-if="element.priority">
                    <div :style="{ background: element.color }">{{ element.priority }}</div>     
                </div>    
                <div class="tsk_priority_main" v-bind:class="'kanLabelCls-'+element.task_id"
                                 v-html="labelsHtml(element.labels)" /> 
            </div>    
            <div class="kanProNameCls">
                <div >{{ element.projects }}</div>     
            </div>
            <div class="kanPriFooterCls">                                    
                <div class="kanFooterCmt_right">
                    <div class="kanFooterDateCls float-left ms-0">
                        <div class="float-right ms-0">{{ dateFormate(element.taskCreatedDate) }}</div>  
                    </div>
                    <div class="kanFooterCmtCls float-left  ms-2">
                        <CommentSvgIcon />
                        <div class="float-right ms-1">{{ element.commentsCounts }}</div>     
                        <div class="clearfix"></div>
                    </div>
                    <div class="kanFooterAttCls float-left  ms-2">
                        <AttachmentSvgIcon />
                        <input type="hidden" v-bind:class="'atCntVal-'+element.task_id" v-bind:value="element.attachmentsCounts"/>
                        <div class="float-right ms-1" v-bind:class="'atCnt-'+element.task_id">{{ element.attachmentsCounts }}</div>     
                        <div class="clearfix"></div>
                    </div>
                </div> 
                <div class="kanFooterCmt_left">
                    <div class="kanTitleClssdsd" v-bind:class="'kanUsersCls-'+element.task_id">
                        <div class="tsk_assign_user" v-html="userImgHtml(element.users)" />     
                    </div>
                </div>                                         
            </div>
        </div>        
    </div>
</template>
<script>
import CommentSvgIcon from '../Widgets/Icon/CommentSvgIcon.vue'
import AttachmentSvgIcon from '../Widgets/Icon/AttachmentSvgIcon.vue'

import moment from 'moment'
export default {
    name: 'karbanItemPage',
    components: {
        CommentSvgIcon,
        AttachmentSvgIcon,
    },
    props: {
        item: {
            type: Array,
        },
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
        }
    },
    methods: {
        imageLoadError(id) {
            console.log('Image failed to load', id);
            // $('.img-'+ id).hide();
        },
        dateFormate(date1) {
            // return moment(date1).format('YYYY-MM-DD HH:mm:ss')
            return moment(date1).format('DD MMM')
        },
        isImage(filePath) {
            if (!filePath) return false;
            const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
            return imageExtensions.test(filePath);
        },
        labelsHtml(labels) {
            var len = labels.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                    htmls += '<div class="tsk_priority" style="background-color: ' + labels[i].color_code + '">';
                        htmls += '<span title="'+labels[i].label+'" class="labelSpanCls">'+labels[i].label+'</span>';
                    htmls += '</div>';
            }
            return htmls;
        },
        userImgHtml(users) {
            var len = users.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                if (len <= 3 || i < 2) {
                    if (users[i].initials == null) {
                        htmls += '<div class="mg-thumbnail">';
                        htmls += '<img src="' + this.img_url + '' + users[i].user_image + '" alt="' + users[i].name + '" title="' + users[i].name + '" />';
                        htmls += '</div>';
                    } else {
                        htmls += '<div class="mg-thumbnail tsk_name">';
                        htmls += '<span title="' + users[i].name + '">' + users[i].initials + '</span>';
                        htmls += '</div>';
                    }
                }
            }
            if (len > 2 && len > 3) {
                htmls += '<div class="mg-thumbnail tsk_number">';
                var cntVal = len - 2;
                htmls += '+' + cntVal;
                htmls += '</div>';
            }
            return htmls;
        },
    },
}

</script>
<style scoped>
.kanPriNameMainCls {
    display: flex;
    justify-content: space-between;
}
</style>